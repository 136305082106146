<script setup lang="ts">
import { ref } from 'vue';

export interface BaseInputProps {
    id: string;
    label: string;
    placeholder?: string;
    error?: string;
    loading?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    autocomplete?: string;
}

const inputRef = ref<HTMLInputElement>();

function setFocus() {
    inputRef.value?.focus();
}

function setFocusAndSelect() {
    inputRef.value?.select();
}

defineProps<BaseInputProps>();
const modelValue = defineModel<string | number | null>();

defineExpose({ setFocus, setFocusAndSelect });
defineOptions({
    inheritAttrs: false,
});
</script>

<template>
    <BaseInputWrapper :loading="loading">
        <input
            v-bind="$attrs"
            class="base-input"
            ref="inputRef"
            v-model="modelValue"
            :placeholder="placeholder || label"
            :id="id"
            :aria-describedby="error ? `${id}-error` : undefined"
            :aria-invalid="!!error"
            :disabled="disabled"
            :readonly="readonly"
            :autocomplete="autocomplete"
        >
    </BaseInputWrapper>
</template>

<style lang="scss" scoped>
.base-input {
    position: relative;

    display: block;

    inline-size: 100%;
    margin: var(--field-margin);
    padding-block: var(--field-padding-block);
    padding-inline: var(--field-padding-inline);

    font-family: var(--default-font-family);
    font-size: var(--field-font-size);
    line-height: var(--field-line-height);
    color: var(--field-color);

    appearance: none;
    background: var(--field-bg);
    background-clip: padding-box;
    border: var(--field-border);
    border-color: var(--field-border-color);
    border-radius: var(--field-border-radius);
    outline: 0;

    transition: .15s ease border-color;

    &::placeholder {
        color: var(--field-placeholder-color);
        opacity: 1;
    }

    &:focus {
        --field-border-color: var(--field-hover-border-color);
    }

    &:disabled,
    &[readonly] {
        --field-bg: var(--field-disabled-bg);

        opacity: 1;
    }

    &:disabled {
        --field-color: var(--color-disabled-color);

        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        --field-border-color: var(--color-primary-light);
    }

    // stylelint-disable-next-line no-descending-specificity
    .-has-error & {
        --field-border-color: var(--field-error-border-color);
        --field-color: var(--field-error-color);
        --field-bg: var(--field-error-bg);
        --field-placeholder-color: var(--field-error-placeholder-color);

        &::placeholder {
            opacity: .5;
        }

        &:hover {
            --field-border-color: var(--field-error-border-color);
        }
    }
}
</style>
