import axios, {
    type AxiosError,
    isCancel,
    type AxiosRequestConfig,
    type RawAxiosRequestHeaders,
    HttpStatusCode,
} from 'axios';
import { API_URL, DEFAULT_HEADERS, DEFAULT_TIMEOUT_IN_MILLIS, HEADER_NAME } from '@/config/api.config';
import { useAuthStore } from '@/stores';
import type { ApiError, ApiErrorResponse, IUrlParams } from '@/types/api';
import { storeToRefs } from 'pinia';
import { reportApiError } from '@/utils/errorReporter';
import { i18n } from '@/config/i18n.config';
import { useToastService } from '@/utils/useToastService';

interface BaseRequestConfig<Result, ResponseData> extends AxiosRequestConfig {
    url: string;
    pathParams?: IUrlParams;
    addAuthorizationHeader?: boolean; // default true
    mapResponse?: (data: ResponseData, responseHeaders?: Partial<RawAxiosRequestHeaders>) => Result;
    appendResponseHeaders?: boolean;
}

interface BodyRequestConfig<Result, ResponseData> extends BaseRequestConfig<Result, ResponseData> {
    body?: object | string;
}

interface RequestWrapper {
    get: <Result, ResponseData = Result>(config: BaseRequestConfig<Result, ResponseData>) => Promise<Result>;
    post: <Result, ResponseData = Result>(config: BodyRequestConfig<Result, ResponseData>) => Promise<Result>;
    put: <Result, ResponseData = Result>(config: BodyRequestConfig<Result, ResponseData>) => Promise<Result>;
    patch: <Result, ResponseData = Result>(config: BodyRequestConfig<Result, ResponseData>) => Promise<Result>;
    remove: <Result, ResponseData = Result>(config: BodyRequestConfig<Result, ResponseData>) => Promise<Result>;
}

interface RequestWrapperConfig {
    defaultBaseURL: string;
    defaultTimeout: number;
    onError?: (error: ApiError<ApiErrorResponse>, payload?: object) => void;
}

const apiClient = axios.create({
    baseURL: API_URL.BASE,
    withCredentials: true,
    headers: { ...DEFAULT_HEADERS },
    timeout: DEFAULT_TIMEOUT_IN_MILLIS,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DEFAULT_RESPONSE_MAPPER_RETURNS_DATA_AS_IS = (data: any): any => data;

export function get<Result, ResponseData = Result>(config: BaseRequestConfig<Result, ResponseData>) {
    return requestWrapper.get<Result, ResponseData>(config);
}

export function post<Result, ResponseData = Result>(config: BodyRequestConfig<Result, ResponseData>) {
    return requestWrapper.post<Result, ResponseData>(config);
}

export function put<Result, ResponseData = Result>(config: BodyRequestConfig<Result, ResponseData>) {
    return requestWrapper.put<Result, ResponseData>(config);
}

export function patch<Result, ResponseData = Result>(config: BodyRequestConfig<Result, ResponseData>) {
    return requestWrapper.patch<Result, ResponseData>(config);
}

export function remove<Result, ResponseData = Result>(config: BaseRequestConfig<Result, ResponseData>) {
    return requestWrapper.remove<Result, ResponseData>(config);
}

const requestWrapper = getRequestWrapper({
    defaultBaseURL: API_URL.BASE,
    defaultTimeout: DEFAULT_TIMEOUT_IN_MILLIS,
    onError: errorHandler,
});

function getRequestWrapper({
    defaultBaseURL = '',
    defaultTimeout,
    onError,
}: RequestWrapperConfig): RequestWrapper {
    function get<Result, ResponseData = Result>({
        url,
        baseURL = defaultBaseURL,
        timeout = defaultTimeout,
        pathParams = {},
        headers = { ...DEFAULT_HEADERS },
        addAuthorizationHeader = true,
        mapResponse = DEFAULT_RESPONSE_MAPPER_RETURNS_DATA_AS_IS,
        appendResponseHeaders = false,
        ...axiosConfig
    }: BaseRequestConfig<Result, ResponseData>): Promise<Result> {
        return apiClient.get(constructResourceUrl({ url, pathParams }), {
            baseURL,
            timeout,
            headers: appendConditionalHeaders({ headers, addAuthorizationHeader }),
            ...axiosConfig,
        })
            .then((response) => {
                if (appendResponseHeaders) {
                    return mapResponse(response.data, response.headers);
                }
                return mapResponse(response.data);
            })
            .catch((error: AxiosError) => {
                const apiError = transformError(error);
                if (onError) {
                    onError(apiError);
                }
                throw apiError;
            });
    }

    function post<Result, ResponseData = Result>({
        url,
        baseURL = defaultBaseURL,
        timeout = defaultTimeout,
        pathParams = {},
        headers = { ...DEFAULT_HEADERS },
        addAuthorizationHeader = true,
        body = {},
        mapResponse = DEFAULT_RESPONSE_MAPPER_RETURNS_DATA_AS_IS,
        ...axiosConfig
    }: BodyRequestConfig<Result, ResponseData>): Promise<Result> {
        return apiClient.post(constructResourceUrl({ url, pathParams }), body, {
            baseURL,
            timeout,
            headers: appendConditionalHeaders({ headers, addAuthorizationHeader }),
            ...axiosConfig,
        })
            .then((response) => mapResponse(response.data))
            .catch((error: AxiosError) => {
                const apiError = transformError(error);
                if (onError) {
                    onError(apiError, typeof body === 'object' ? body : undefined);
                }
                throw apiError;
            });
    }

    function put<Result, ResponseData = Result>({
        url,
        baseURL = defaultBaseURL,
        timeout = defaultTimeout,
        pathParams = {},
        headers = { ...DEFAULT_HEADERS },
        addAuthorizationHeader = true,
        body = {},
        mapResponse = DEFAULT_RESPONSE_MAPPER_RETURNS_DATA_AS_IS,
        ...axiosConfig
    }: BodyRequestConfig<Result, ResponseData>): Promise<Result> {
        return apiClient.put(constructResourceUrl({ url, pathParams }), body, {
            baseURL,
            timeout,
            headers: appendConditionalHeaders({ headers, addAuthorizationHeader }),
            ...axiosConfig,
        })
            .then((response) => mapResponse(response.data))
            .catch((error: AxiosError) => {
                const apiError = transformError(error);
                if (onError) {
                    onError(apiError, typeof body === 'object' ? body : undefined);
                }
                throw apiError;
            });
    }

    function patch<Result, ResponseData = Result>({
        url,
        baseURL = defaultBaseURL,
        timeout = defaultTimeout,
        pathParams = {},
        headers = { ...DEFAULT_HEADERS },
        addAuthorizationHeader = true,
        body = {},
        mapResponse = DEFAULT_RESPONSE_MAPPER_RETURNS_DATA_AS_IS,
        ...axiosConfig
    }: BodyRequestConfig<Result, ResponseData>): Promise<Result> {
        headers[HEADER_NAME.CONTENT_TYPE] = 'application/merge-patch+json';

        return apiClient.patch(constructResourceUrl({ url, pathParams }), body, {
            baseURL,
            timeout,
            headers: appendConditionalHeaders({ headers, addAuthorizationHeader }),
            ...axiosConfig,
        })
            .then((response) => mapResponse(response.data))
            .catch((error: AxiosError) => {
                const apiError = transformError(error);
                if (onError) {
                    onError(apiError, typeof body === 'object' ? body : undefined);
                }
                throw apiError;
            });
    }

    function remove<Result, ResponseData = Result>({
        url,
        baseURL = defaultBaseURL,
        timeout = defaultTimeout,
        pathParams = {},
        headers = { ...DEFAULT_HEADERS },
        addAuthorizationHeader = true,
        mapResponse = DEFAULT_RESPONSE_MAPPER_RETURNS_DATA_AS_IS,
        ...axiosConfig
    }: BaseRequestConfig<Result, ResponseData>): Promise<Result> {
        return apiClient.delete(constructResourceUrl({ url, pathParams }), {
            baseURL,
            timeout,
            headers: appendConditionalHeaders({ headers, addAuthorizationHeader }),
            ...axiosConfig,
        })
            .then((response) => mapResponse(response.data))
            .catch((error: AxiosError) => {
                const apiError = transformError(error);
                if (onError) {
                    onError(apiError);
                }
                throw apiError;
            });
    }

    function transformError(error: AxiosError): ApiError<ApiErrorResponse> {
        const requestUrl = error.config && error.config.url && constructResourceUrl({
            url: error.config.url,
            baseUrl: error.config.baseURL,
            queryParams: error.config.params,
        });

        return {
            status: error.response?.status || HttpStatusCode.RequestTimeout,
            originatingRequest: {
                url: requestUrl,
                method: error.config?.method?.toUpperCase(),
            },
            wasCancelled: isCancel(error),
            response: typeof error.response?.data === 'object'
                ? error.response.data as ApiErrorResponse
                : { message: 'UNKNOWN' },
        };
    }

    return {
        get,
        post,
        put,
        patch,
        remove,
    };
}

function appendConditionalHeaders<Result, ResponseData>({
    headers,
    addAuthorizationHeader,
}: Pick<BaseRequestConfig<Result, ResponseData>, 'headers' | 'addAuthorizationHeader'>) {
    const newHeaders: RawAxiosRequestHeaders = { ...headers };

    if (addAuthorizationHeader) {
        const authStore = useAuthStore();
        if (authStore.loginData.data?.token) {
            newHeaders[HEADER_NAME.AUTHORIZATION] = `Bearer ${authStore.loginData.data.token}`;
        }
    }

    return newHeaders;
}

function constructResourceUrl({ url, baseUrl = '', pathParams, queryParams }: {
    url: string;
    baseUrl?: string;
    pathParams?: IUrlParams;
    queryParams?: IUrlParams;
}): string {
    let constructedUrl = `${baseUrl}${url}`;

    if (pathParams) {
        Object.entries(pathParams).forEach(([key, value]) => {
            constructedUrl = constructedUrl.replace(`:${key}`, encodeURIComponent(value));
        });
    }

    if (queryParams) {
        const inputParams = new URLSearchParams();
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value) {
                inputParams.set(key, value.toString());
            }
        });

        constructedUrl = `${constructedUrl}?${inputParams.toString()}`;
    }

    return constructedUrl;
}

function errorHandler(error: ApiError<ApiErrorResponse>, payload?: object) {
    if (error.wasCancelled) {
        // ignore canceled request errors
        return;
    }

    if (error.status === HttpStatusCode.Unauthorized) {
        const authStore = useAuthStore();
        const { isLoggedIn, unauthorizedLogoutHandled } = storeToRefs(authStore);

        if (isLoggedIn.value && !unauthorizedLogoutHandled.value) {
            authStore.setUnauthorizedLogoutHandled(true);
            const toastService = useToastService();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { t } = i18n.global as any;

            authStore.forceLogout();
            toastService.add({
                severity: 'error',
                summary: t('error.unauthorized.summary'),
                detail: t('error.unauthorized.detail'),
            });
        }
    }

    reportApiError(error, payload);
}
