import { ref } from 'vue';
import { useConstantsStore } from '@/stores';
import { storeToRefs } from 'pinia';

const printingAPIEnabled = ref(false);

export function useChromePrintingApi() {
    const constantsStore = useConstantsStore();
    const { preferredPrinterId } = storeToRefs(constantsStore);

    function waitForExtension() {
        if (typeof window.printing !== 'undefined') {
            return Promise.resolve;
        }

        return new Promise<void>((resolve) => {
            window.addEventListener('printingApiInitialized', () => {
                resolve();
            }, { once: true });
        });
    }

    async function enablePrintingAPI() {
        await waitForExtension();
        printingAPIEnabled.value = true;
    }

    async function printReceipt({
        pdf,
        heightInMicrons,
        afterPrint,
    }: {
        pdf: string;
        heightInMicrons?: number;
        afterPrint?: () => void;
    }) {
        if (printingAPIEnabled.value) {
            try {
                if (preferredPrinterId.value && await validatePrinter(preferredPrinterId.value)) {
                    return printWithChromeApi({
                        printerId: preferredPrinterId.value,
                        pdf,
                        heightInMicrons,
                    });
                }

                constantsStore.showPrinterSelectDialog({
                    onPrinterSelected: async (printerId: string) => {
                        if (await validatePrinter(printerId)) {
                            return printWithChromeApi({
                                printerId,
                                pdf,
                                heightInMicrons,
                            }).finally(() => {
                                if (afterPrint) {
                                    afterPrint();
                                }
                            });
                        }

                        return Promise.reject(new Error('Invalid printer id'));
                    },
                });
            } catch (error) {
                return Promise.reject(new Error('Printing failed'));
            }
        }

        return Promise.reject(new Error('Printing API not enabled'));
    }

    function printWithChromeApi({ printerId, pdf, heightInMicrons = 1999540 }: {
        printerId: string;
        pdf: string;
        heightInMicrons?: number,
    }) {
        return window.printing.submitJob(
            printerId,
            'cash register receipt print',
            pdf,
            {
                /* eslint-disable camelcase */
                color: {
                    type: 'STANDARD_MONOCHROME',
                },
                duplex: {
                    type: 'NO_DUPLEX',
                },
                copies: { copies: 1 },
                dpi: {
                    horizontal_dpi: 300,
                    vertical_dpi: 300,
                },
                collate: { collate: false },
                page_orientation: {
                    type: 'PORTRAIT',
                },
                media_size: {
                    height_microns: heightInMicrons,
                    width_microns: 71970,
                },
                /* eslint-enable camelcase */
            },
        );
    }

    function getPrinters() {
        return window.printing.getPrinters();
    }

    async function validatePrinter(id: string) {
        const printers = await getPrinters();
        return printers.some((printer) => printer.id === id);
    }

    return {
        enablePrintingAPI,
        printReceipt,
        getPrinters,
    };
}
