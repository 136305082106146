import type { App } from 'vue';
import type { Router } from 'vue-router';
import {
    BrowserTracing,
    captureMessage,
    init,
    vueRouterInstrumentation,
    withScope,
} from '@sentry/vue';
import {
    ERROR_STATUS_NOT_TO_REPORT,
    SENTRY_DSN,
    SENTRY_ENABLED,
    SENTRY_ENVIRONMENT,
    SENTRY_RATES,
} from '@/config/errorReporting.config';
import type { ApiError, ApiErrorResponse } from '@/types/api';
import { isApiMessageError, isApiPlatformError, isApiPlatformHydraError } from '@/utils/api';

export function initErrorReporting({ app, router }: { app: App, router: Router }) {
    if (SENTRY_ENABLED) {
        init({
            app,
            dsn: SENTRY_DSN,
            environment: SENTRY_ENVIRONMENT,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: vueRouterInstrumentation(router),
                }),
                // new Replay(),
            ],
            ignoreErrors: [
                'ResizeObserver loop completed with undelivered notifications',
            ],
            ...SENTRY_RATES,
        });
    }
}

export function reportApiError(error: ApiError<ApiErrorResponse>, payload?: object) {
    const { status } = error;

    if (SENTRY_DSN && status && shouldReportApiError(status)) {
        const message = isApiMessageError(error)
            ? error.response?.message
            : isApiPlatformError(error)
                ? `${error.response.title} - ${error.response.detail}`
                : isApiPlatformHydraError(error)
                    ? `${error.response['hydra:title']} - ${error.response['hydra:description']}`
                    : '-';
        // eslint-disable-next-line max-len
        const reportMessage = `ApiError - method: ${error.originatingRequest.method} - url: ${error.originatingRequest.url} - status: ${status} - message: ${message}`;
        withScope((scope) => {
            scope.setLevel('info');
            if (payload) {
                scope.setContext('body', payload as Record<string, unknown>);
            }
            captureMessage(reportMessage);
        });
    }
}

/**
 * To reduce the number of error logs we send to Sentry,
 * otherwise we risk hitting the sentry rate limit.
 */
function shouldReportApiError(status: number): boolean {
    return !ERROR_STATUS_NOT_TO_REPORT.includes(status);
}
